.ant-btn-primary {
  background-color: #3fc060 !important;
  border-color: #3fc060 !important;
}

.ant-pagination-item-active {
  border-color: #3fc060 !important;
}

li.ant-pagination-item-active a {
  color: #3fc060 !important;
}

.ant-menu-item-selected {
  background-color: #3fc060 !important;
}

.ant-menu-item-selected span {
  color: white !important;
}

.ant-menu-item-active {
  color: #3fc060 !important;
}